import React from "react";
import "../AboutMe/AboutMeComponent.scss";
import PropTypes from "prop-types";
import "../../../node_modules/video-react/dist/video-react.css";
import { Player } from "video-react";
import closeIcon from "../../common/assets/images/cross-icon.png";
import appConstants from "../../constants/constants";
import HLSSource from "./HLSSource";
import * as CommonService from "../../common/services/common.service";

export default class AboutMeVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullScreenMode: false,
    }
  }

  componentDidMount() {
    // subscribe state change
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }

  // Below is the code for removing spinner while skipping forward or backward on video player in Edge
  handleStateChange(state) {
    if (document.querySelector(".video-react")) {
      const element = document.querySelector(".video-react");
      this.funcName = "handleStateChange";
      if (
        element.classList.contains("video-react-playing") &&
        element.classList.contains("video-react-waiting")
      ) {
        element.classList.remove("video-react-waiting");
      }
    }
    if(state.isFullscreen) {
      this.setState(() => ({
        fullScreenMode: true
      }));
    }
    else {
      this.setState(() => ({
        fullScreenMode: false
      }));
    }
  }

  render() {
    const { closeModal, videoUrl, thumbnailUrl } = this.props;
    const { fullScreenMode } = this.state;
    return (
      <div className="aboutMeVideo">
        <button className="cancel" onClick={closeModal}>
          <img src={closeIcon} alt="close" />
        </button>
        <div
          className={`aboutMeInnerVideo ${
            thumbnailUrl === appConstants.defaultThumbnail
              ? "video-custom-border"
              : ""
          }
          ${fullScreenMode && "videoTransform"}`}
        >
          {CommonService.getExtension(videoUrl) === "m3u8" && (
            <Player
              fluid={false}
              width="100%"
              height="100%"
              ref={player => {
                this.player = player;
              }}
            >
              <HLSSource isVideoChild poster={thumbnailUrl} src={videoUrl} />
            </Player>
          )}
          {(CommonService.getExtension(videoUrl) === "mp4" ||
            CommonService.getExtension(videoUrl) === "mov") && (
            <Player
              ref={player => {
                this.player = player;
              }}
              playsInline
              poster={thumbnailUrl}
              src={videoUrl}
              fluid={false}
              width="100%"
              height="100%"
            />
          )}
        </div>
      </div>
    );
  }
}

AboutMeVideo.propTypes = {
  closeModal: PropTypes.func.isRequired,
  videoUrl: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string.isRequired
};
