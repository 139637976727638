import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import NumberFormat from "react-number-format";
import * as translateService from "../../common/services/translate.service";
import "./ContactInfoComponent.scss";

export default class ContactInfoComponent extends React.Component {
  render() {
    const { contactDetails } = this.props;
    const date = new Date().getTime();
    return (
      <div className="contactInfoContainer contactHeader">
        <Container>
          <Row>
            <Col className="profileImageSection">
              {contactDetails && contactDetails.profileImage && (
                <div className="profileImageDiv">
                  <img
                    src={`${contactDetails.profileImage}?${date}`}
                    className="d-inline-block profileImage"
                    alt={translateService.translate("profile_icon")}
                  />
                </div>
              )}
            </Col>
            <Col className="contactInfoDetailsSection">
              {contactDetails &&
                contactDetails.first_name &&
                contactDetails.last_name && (
                  <p className="profileName">
                    {contactDetails.first_name} {contactDetails.last_name}
                  </p>
                )}
              {contactDetails && contactDetails.teamName && (
                <p className="profileTeamName">
                  {contactDetails.teamName}
                </p>
              )}
              {contactDetails &&
                (contactDetails.teamName ||
                  contactDetails.first_name ||
                  contactDetails.last_name) && (
                  <p className="profileTagline">
                    {contactDetails.tagLine
                      ? contactDetails.tagLine
                      : translateService.translate(
                          "independent_team_beachbody_code"
                        )}
                  </p>
                )}
              {contactDetails && contactDetails.email && (
                <p className="profileEmail wrapWord">
                  <i className="fas fa-envelope emailIcon"></i>
                  <a href={"mailto:" + contactDetails.email}>
                    {contactDetails.email}
                  </a>
                </p>
              )}
              {contactDetails && contactDetails.phone && (
                <p className="profileContact">
                  <i className="fas fa-phone-alt phoneIcon"></i>
                  <a
                    href={
                      "tel:+" +
                      contactDetails.phoneCountry +
                      contactDetails.phone
                    }
                  >
                    <NumberFormat
                      format="######"
                      displayType={"text"}
                      value={contactDetails.phoneCountry}
                      type="tel"
                    />
                    <NumberFormat
                      format="-###-###-####"
                      displayType={"text"}
                      value={contactDetails.phone}
                      type="tel"
                    />
                  </a>
                </p>
              )}
              {contactDetails && contactDetails.personal_website && (
                <p className="profileWebsite wrapWord">
                  <i className="fas fa-tv websiteIcon"></i>
                  <a
                    href={contactDetails.personal_website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {contactDetails.personal_website}
                  </a>
                </p>
              )}
              <div className="contactIconsSection">
                {contactDetails && contactDetails.facebook && (
                  <div
                    className="iconSection"
                    onClick={() => window.open(contactDetails.facebook)}
                    title={contactDetails.facebook}
                  >
                    <i className="fab fa-facebook-f facebookIcon"></i>
                  </div>
                )}
                {contactDetails && contactDetails.twitter && (
                  <div
                    className="iconSection"
                    onClick={() => window.open(contactDetails.twitter)}
                    title={contactDetails.twitter}
                  >
                    <i className="fab fa-twitter twitterIcon"></i>
                  </div>
                )}
                {contactDetails && contactDetails.instagram && (
                  <div
                    className="iconSection"
                    onClick={() => window.open(contactDetails.instagram)}
                    title={contactDetails.instagram}
                  >
                    <i className="fab fa-instagram instagramIcon"></i>
                  </div>
                )}
                {contactDetails && contactDetails.pinterest && (
                  <div
                    className="iconSection"
                    onClick={() => window.open(contactDetails.pinterest)}
                    title={contactDetails.pinterest}
                  >
                    <i className="fab fa-pinterest-p pinterestIcon"></i>
                  </div>
                )}
                {contactDetails && contactDetails.youtube && (
                  <div
                    className="iconSection"
                    onClick={() => window.open(contactDetails.youtube)}
                    title={contactDetails.youtube}
                  >
                    <i className="fab fa-youtube youtubeIcon"></i>
                  </div>
                )}
                {contactDetails && contactDetails.snapchat && (
                  <div
                    className="iconSection"
                    onClick={() => window.open(contactDetails.snapchat)}
                    title={contactDetails.snapchat}
                  >
                    <i className="fab fa-snapchat-ghost snapchatIcon"></i>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
