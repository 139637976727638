import React from "react";
import { Container, CardDeck } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import MyGroupsCard from "./MyGroupsCard";
import * as translateService from "../../common/services/translate.service";
import "./MyGroupsComponent.scss";
import ScrollableAnchor from "react-scrollable-anchor";

export default class MyGroupsComponent extends React.Component {
  createCards = () => {
    const { myGroupDetails, coachDetails } = this.props;
    let cards = [];
    if (myGroupDetails) {
      for (let i = 0; i < myGroupDetails.groups.length; i++) {
        cards.push(
          <MyGroupsCard
            groupDetail={myGroupDetails.groups[i]}
            key={myGroupDetails.groups[i].id}
            coachId={coachDetails.coachId}
          />
        );
      }
    }
    // if (cards.length === 0) {
    //   const noGroupsMsg = 'No groups found';
    //   cards.push(noGroupsMsg);
    // }
    return cards;
  };

  render() {
    const { myGroupDetails, coachDetails, displayLoader } = this.props;
    let groupsData = '';
    if (!displayLoader && coachDetails && coachDetails.groupsInfo) {
      groupsData = coachDetails.groupsInfo.replace(/<p><br><\/p>/g, '');
    }
    return (
      <div className="myGroupsContainer">
        <Container>
          <ScrollableAnchor id={"myGroups"}>
            <div className="myGroupsHeader headerStyle text-center">
              {translateService.translate("my_groups")}
            </div>
          </ScrollableAnchor>
          {displayLoader && (
            <div className="loaderContainer">
              <div className="loader"></div>
            </div>
          )}
          {!displayLoader && (
            <div>
              {groupsData && (
                  <div className="myGroupsSubtitle">
                    {ReactHtmlParser(
                        groupsData.replace(
                        /contenteditable="false"/g,
                        ""
                      )
                    )}
                  </div>
                )}
                {
                  myGroupDetails && myGroupDetails.groups && myGroupDetails.groups.length > 0 &&
                  <div className="myGroupsCardsSection" >
                    <CardDeck>{this.createCards()}</CardDeck>
                  </div>
                }
            </div>
          )}
        </Container>
      </div>
    );
  }
}
