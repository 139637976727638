import React from "react";
import { Container } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import * as translateService from "../../common/services/translate.service";
import { ModalStyle } from "../../constants/constants";
import "./AboutMeComponent.scss";
import ScrollableAnchor from "react-scrollable-anchor";
import Modal from "react-modal";
import AboutmeVideo from "../Modal/AboutMeVideo";
import appConstants from "../../constants/constants";
import { isMobileOnly } from "react-device-detect";
import { sanitize } from "sanitize-filename-ts";

let responsive = {};

if (isMobileOnly) {
  responsive = {
    galaxyS5: {
      breakpoint: { max: 640, min: 360 },
      items: 1,
      partialVisibilityGutter: 40
    },
    pixel2: {
      breakpoint: { max: 731, min: 411 },
      items: 1,
      partialVisibilityGutter: 110
    },
    pixel2XL: {
      breakpoint: { max: 823, min: 411 },
      items: 1,
      partialVisibilityGutter: 110
    },
    iphone8: {
      breakpoint: { max: 667, min: 375 },
      items: 1,
      partialVisibilityGutter: 60
    },
    iphone8p: {
      breakpoint: { max: 736, min: 414 },
      items: 1,
      partialVisibilityGutter: 0
    },
    iphone11plus: {
      breakpoint: { max: 896, min: 414 },
      items: 1,
      partialVisibilityGutter: 95
    }
  };
} else {
  responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 10
    },
    ipadmini: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      partialVisibilityGutter: 60
    },
    ipadpro: {
      breakpoint: { max: 1112, min: 834 },
      items: 2,
      partialVisibilityGutter: 60
    },
    ipadpro12: {
      breakpoint: { max: 1366, min: 1024 },
      items: 2,
      partialVisibilityGutter: 120
    }
  };
}

export default class AboutMeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      isModalOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handlePhotoScroll = this.handlePhotoScroll.bind(this);
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  handlePhotoScroll() {
    window.addEventListener(
      "orientationchange",
      () => {
        this.Carousel.goToSlide(0);
      },
      false
    );
  }

  render() {
    const { aboutMeDetails } = this.props;
    const { isModalOpen, photoIndex, isOpen } = this.state;

    let photoGallery =
      aboutMeDetails && aboutMeDetails.photoGallery
        ? aboutMeDetails.photoGallery
        : [];

    let videoGallery =
      aboutMeDetails && aboutMeDetails.video ? aboutMeDetails.video : [];

    let videoUrl = "";
    let thumbnailUrl = appConstants.defaultThumbnail;

    if (
      videoGallery &&
      videoGallery.length > 0 &&
      videoGallery[0].selected_thumbnail_url
    ) {
      thumbnailUrl = videoGallery[0].selected_thumbnail_url;
    }

    if (videoGallery.length > 0) {
      videoUrl = videoGallery[0].url;
    }

    const videoSection = photoGallery.length > 0 ? "videoSection" : "";
    const photoSection = videoGallery.length > 0 ? "photoGalleryLayout" : "";

    let showGallerySection = false;
    // Here length should be check for video gallery.
    showGallerySection =
      videoGallery.length === 1 && photoGallery.length === 1 ? true : false;

    const images = photoGallery.map((value, index) => {
      return value.url;
    });

    const captions = photoGallery.map((value, index) => {
      return value.caption;
    });

    let nextImage, prevImage;
    nextImage =
      images.length > 1 ? images[photoIndex % images.length] : undefined;
    prevImage =
      images.length > 1
        ? images[(photoIndex + images.length) % images.length]
        : undefined;

    let customClassPhotoGallery =
      photoGallery.length === 2 ? "adjustWidth removeTransform" : undefined;
    let mobileClassPhotoGallery =
      photoGallery.length >= 3 ? "mobileWidth" : undefined;
    return (
      <div className="aboutMeContainer">
        <Container>
          <ScrollableAnchor id={"aboutMe"}>
            <div className="aboutMeHeader headerStyle text-center">
              {translateService.translate("about_me")}
            </div>
          </ScrollableAnchor>
          <div className="aboutMeMyBio">
            {aboutMeDetails &&
              aboutMeDetails.bio &&
              sanitize(aboutMeDetails.bio).trim() !== "" &&
              ReactHtmlParser(
                aboutMeDetails.bio.replace(/contenteditable="false"/g, "")
              )}
            {aboutMeDetails &&
              (!aboutMeDetails.bio ||
                sanitize(aboutMeDetails.bio).trim() === "") &&
              ReactHtmlParser(
                translateService.translate("about_me_description")
              )}
          </div>
          {(aboutMeDetails.showLifetimeRank === 1 ||
            aboutMeDetails.showRoadToElite === 1 ||
            aboutMeDetails.showShakeology365 === 1 ||
            aboutMeDetails.showSuccessClub === 1) && (
            <ul className="myAchievements">
              {aboutMeDetails.showLifetimeRank === 1 &&
                aboutMeDetails.lifetimeRank && (
                  <li className="achievementText">
                    {aboutMeDetails.lifetimeRank}
                  </li>
                )}
              {aboutMeDetails.showRoadToElite === 1 &&
                aboutMeDetails.roadToElite && (
                  <li className="achievementText">
                    {aboutMeDetails.roadToElite}
                  </li>
                )}
              {aboutMeDetails.showShakeology365 === 1 &&
                aboutMeDetails.shakeology365 === true && (
                  <li className="achievementText">
                    {translateService.translate("shakeology_365")}
                  </li>
                )}
              {aboutMeDetails.showSuccessClub === 1 &&
                aboutMeDetails.mySuccessClubStatus.successClub5 && (
                  <li className="achievementText">
                    {aboutMeDetails.mySuccessClubStatus.successClub5}
                  </li>
                )}
              {aboutMeDetails.showSuccessClub === 1 &&
                aboutMeDetails.mySuccessClubStatus.successClub10 && (
                  <li className="achievementText">
                    {aboutMeDetails.mySuccessClubStatus.successClub10}
                  </li>
                )}
            </ul>
          )}

          {showGallerySection !== true && (
            <div className="carouselContainer text-center">
              {videoGallery.length > 0 && videoUrl && thumbnailUrl && (
                <div
                  className={`text-center videoContainer ${videoSection}`}
                  id={photoGallery.length === 0 && `videoModal`}
                >
                  <img
                    src={thumbnailUrl}
                    alt={translateService.translate("video")}
                    className={`${
                      thumbnailUrl === appConstants.defaultThumbnail
                        ? "video-custom-border"
                        : ""
                    }`}
                  />
                  <button
                    className="video-react-big-play-button video-react-big-play-button-left"
                    type="button"
                    onClick={this.openModal}
                  >
                    <span className="video-react-control-text">
                      {translateService.translate("play_video")}
                    </span>
                  </button>
                </div>
              )}
              {photoGallery.length > 0 && (
                <div
                  className={`text-center photoGallerySection ${photoSection} ${customClassPhotoGallery} ${mobileClassPhotoGallery}`}
                >
                  <Carousel
                    ref={el => (this.Carousel = el)}
                    responsive={responsive}
                    keyBoardControl={false}
                    draggable={false}
                    afterChange={() => {
                      this.handlePhotoScroll();
                    }}
                    partialVisible={photoGallery.length > 3}
                    additionalTransfrom={0}
                    arrows
                    centerMode={false}
                  >
                    {photoGallery.map((value, index) => (
                      <div key={index}>
                        <img
                          src={value.url}
                          alt={translateService.translate("photo")}
                          onClick={e =>
                            this.setState({ photoIndex: index, isOpen: true })
                          }
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
              )}
            </div>
          )}
          {showGallerySection &&
            videoGallery.length > 0 &&
            videoUrl &&
            thumbnailUrl && (
              <div className="carouselContainer text-center">
                <div className="gallerySection text-center">
                  <div className="videoContainer videoGalleryModal">
                    <img
                      src={thumbnailUrl}
                      alt={translateService.translate("video")}
                      className={`${
                        thumbnailUrl === appConstants.defaultThumbnail
                          ? "video-custom-border"
                          : ""
                      }`}
                    />
                    <button
                      className="video-react-big-play-button video-react-big-play-button-left"
                      type="button"
                      onClick={this.openModal}
                    >
                      <span className="video-react-control-text">
                        {translateService.translate("play_video")}
                      </span>
                    </button>
                  </div>
                  {photoGallery.map((value, index) => (
                    <img
                      src={value.url}
                      alt=""
                      onClick={e =>
                        this.setState({ photoIndex: index, isOpen: true })
                      }
                      className="singleImg"
                      key={value.media_order}
                    />
                  ))}
                </div>
              </div>
            )}
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={nextImage}
              prevSrc={prevImage}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length
                })
              }
              wrapperClassName="photoModal"
              imageTitle={
                photoIndex +
                1 +
                translateService.translate("of") +
                images.length
              }
              imageCaption={captions[photoIndex]}
              enableZoom={false}
              clickOutsideToClose={false}
            />
          )}
          {/*Here length should be check for video modal.*/}
          {videoGallery.length > 0 && videoUrl && thumbnailUrl && (
            <Modal
              isOpen={isModalOpen}
              shouldCloseOnOverlayClick={false}
              onRequestClose={this.closeModal}
              className="Modal"
              style={ModalStyle}
              overlayClassName="Overlay_Modal"
              ariaHideApp={false}
              centered
            >
              <AboutmeVideo
                aboutMeDetails={aboutMeDetails}
                closeModal={this.closeModal}
                thumbnailUrl={thumbnailUrl}
                videoUrl={videoUrl}
              />
            </Modal>
          )}
        </Container>
      </div>
    );
  }
}
