import React from "react";
import { Container, Navbar } from "react-bootstrap";
import * as translateService from "../../common/services/translate.service";
import DSALogo from "../../common/assets/images/DSA_Logo.svg";
import BBBTrust from "../../common/assets/images/BBBTrust.png";
import "./FooterComponent.scss";

export default class FooterComponent extends React.Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <Container>
        <Navbar className="footer">
          <div className="footerCopyrightWrapper">
            <span className="copyrightText">
              {translateService.translate("copyright").replace("BBYear", year)}
            </span>
          </div>
          <Navbar.Collapse className="copyRightNav">
            <Navbar.Brand>
              <img
                src={DSALogo}
                alt={translateService.translate("dsa_logo")}
                className="DSAIcon"
              />
              <img
                src={BBBTrust}
                alt={translateService.translate("bbb_trust_icon")}
                className="BBBTrustIcon"
              />
            </Navbar.Brand>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    );
  }
}
