import React from "react";
import { Card } from "react-bootstrap";
import Moment from "react-moment";
import * as i18n from "../../common/services/translate.service";
import appConstants from "../../constants/constants";
import ProfileImage from "../../common/assets/images/default_logo_groups.png";

export default class MyGroupsCard extends React.Component {
  render() {
    const { groupDetail, coachId } = this.props;
    let displayImage = ProfileImage;
    if (groupDetail.image && groupDetail.image.imageCroppedUrl) {
      displayImage = groupDetail.image.imageCroppedUrl;
    } else if (groupDetail.image.imageOriginalUrl) {
      displayImage = groupDetail.image.imageOriginalUrl;
    }
    return (
      <Card className="myGroupsCards">
        <a
          href={`${appConstants.groupsLearnMore}/groups/${groupDetail.id}/${coachId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="myGroupsCardsContent">
            <Card.Img src={displayImage} alt="Group Image" className="groupCardImage" />
            <div className="myGroupsImgOverlay">
              <Card.Text>
                <span className="groupName" title={groupDetail.name}>
                {groupDetail.name.length > 36 ? `${groupDetail.name.substring(0, 36)}...` : groupDetail.name || ""}</span>
                {groupDetail && groupDetail.startDate && groupDetail.endDate && (
                  <span className="groupDate">
                    <Moment date={groupDetail.startDate} format="MMM D" /> -
                    <Moment date={groupDetail.endDate} format="MMM D" />
                  </span>
                )}

                {groupDetail && !groupDetail.endDate && (
                    <span className="groupDate">{i18n.translate("ongoing")}</span>
                )}
              </Card.Text>
            </div>
          </div>
          <div className="myGroupsFooterBtn">
            <button className="btn btn-outline-primary" size="sm">
              {i18n.translate("learn_more")}
            </button>
          </div>
        </a>
      </Card>
    );
  }
}
