import React from "react";
import NavbarComponent from "../components/NavBar/NavbarComponent";
import ContactInfoComponent from "../components/ContactInfo/ContactInfoComponent";
import AboutMeComponent from "../components/AboutMe/AboutMeComponent";
import MyGroupsComponent from "../components/MyGroups/MyGroupsComponent";
import MoreAboutMeComponent from "../components/MoreAboutMe/MoreAboutMeComponent";
import FooterLinksComponent from "../components/FooterLinks/FooterLinksComponent";
import FooterComponent from "../components/Footer/FooterComponent";
import * as CommonService from "../common/services/common.service";
import * as translateService from "../common/services/translate.service";
import { appConstants, defaultPageIndex } from "../constants/constants";
import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor";
import "./MySiteContainer.scss";

export default class MySiteContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: {
        tagLine: translateService.translate("independent_team_beachbody_code"),
      },
      configDetails: [],
      groupsDetails: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    configureAnchors({ offset: -70, scrollDuration: 600 });
    const urlParams = new URLSearchParams(window.location.search);
    const paramCoachId = urlParams.get("coachId") || '';
    const paramLocale = urlParams.get("locale") || '';
    this.getCoachDetails(paramCoachId, paramLocale);
  }

  getCoachDetails(paramCoachId, paramLocale) {
    const apiUrl = appConstants.apiUrlConstant
      .replace("%s", paramCoachId)
      .replace("%s", paramLocale);
    CommonService.getDetails(apiUrl)
      .then(response => {
        // If the coach data is empty, throw error
        // which redirects to the correct environment of Team Beachbody.
        if (!response || !response[0]) {
          const emptyCoachMessage = `No coach data found for coach ID: ${paramCoachId}.`;
          const redirectMessage = `Redirecting to ${appConstants.redirectLink}.`;
          throw new Error(`${emptyCoachMessage} ${redirectMessage}`);
        }
        return response;
      })
      .then(response => {
        if (response && response[0]) {
          this.setState({ userDetails: response[0] });
          // Forcefully redirect user to preferred English locale if they try to open any other english locale
          CommonService.redirectToPreferredEnglishLocale(
            response[0].preferredLanguage,
            paramLocale
          );
          const configData = CommonService.getMySiteConfig(response[0]);
          let groupsInfo = response[0].groupsInfo
            ? response[0].groupsInfo.replace(appConstants.blankTagRegEx, "")
            : "";
          if (!groupsInfo || groupsInfo === "<p><br></p>") {
            configData.myGroups = false;
          }
          this.setState({ configDetails: configData });
          if (response[0].displayGroups) {
            this.getGroupDetails(paramCoachId);
          }
          document.title = response[0].first_name + " " + response[0].last_name;
        }
      })
      .catch((err) => {
        console.error(err);
        window.open(appConstants.redirectLink, "_self");
      });
  }

  getGroupDetails(paramCoachId) {
    this.setState({ isLoading: true });
    const apiUrl = appConstants.apiUrlGroupDetails
      .replace("%s", paramCoachId)
      .replace("%s", defaultPageIndex);
    CommonService.getDetails(apiUrl, false)
      .then((response) => {
        this.setState({
          groupsDetails: response,
          isLoading: false,
        });
        const configDetails = this.state.configDetails;
        const userDetails = this.state.userDetails;
        let groupsInfo =
          userDetails && userDetails.groupsInfo
            ? userDetails.groupsInfo.replace(appConstants.blankTagRegEx, "")
            : "";
        if (!response) {
          configDetails.myGroups =
            groupsInfo !== "" && groupsInfo !== "<p><br></p>";
          this.setState({ configDetails });
        } else {
          configDetails.myGroups = !(
            response.groups.length === 0 &&
            (!groupsInfo || groupsInfo === "<p><br></p>")
          );
          this.setState({ configDetails });
        }
      })
      .catch((err) => {
        this.setState({ groupsDetails: null, isLoading: false });
        console.error(err);
      });
  }

  getLocale() {
    const locale = CommonService.getLocale();
    const userLocale =
      locale === "en_CA" || locale === "en_GB" ? "en_US" : locale;
    return userLocale;
  }

  render() {
    const { userDetails, configDetails, groupsDetails, isLoading } = this.state;
    const userLocale = this.getLocale();
    return (
      <div className={"containerSection " + userLocale}>
        <div className="navbarSection">
          <NavbarComponent
            navDetails={userDetails}
            configDetails={configDetails}
          />
        </div>
        <ScrollableAnchor id={"contact"}>
          <div className="hiddenDiv">&nbsp;</div>
        </ScrollableAnchor>
        <div className="contactInfoSection">
          <ContactInfoComponent contactDetails={userDetails} />
        </div>
        <div className="aboutMeSection">
          <AboutMeComponent aboutMeDetails={userDetails} />
        </div>
        {configDetails && configDetails.myGroups && (
          <div className="myGroupsSection">
            <MyGroupsComponent
              myGroupDetails={groupsDetails}
              coachDetails={userDetails}
              displayLoader={isLoading}
            />
          </div>
        )}
        {configDetails && configDetails.moreAboutMe && (
          <div className="moreAboutMeSection">
            <MoreAboutMeComponent moreAboutMeDetails={userDetails} />
          </div>
        )}
        <div className="bbLinksSection">
          <FooterLinksComponent bbLinksDetails={userDetails} />
        </div>
        <div className="footerSection">
          <FooterComponent />
        </div>
      </div>
    );
  }
}
