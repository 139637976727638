import i18n from '../../constants/translation/i18n';
import * as commonService from '../services/common.service';

export const translate = key => {
  let lang = commonService.getLocale() || 'en_US';             // Modify the language variable optionally here
  return i18n.t(key, { lng: lang });
};
export const translateForSpecificLang = (key, lang) => {
  return i18n.t(key, { lng: lang });
};
