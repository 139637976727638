import React from "react";
import * as translateService from "../../common/services/translate.service";
import { Navbar, Nav, Image, Container } from "react-bootstrap";
import { slide as Menu } from "react-burger-menu";
import ProfileImage from "../../common/assets/images/bb-icon-blue.png";
import hamburgerIcon from "../../common/assets/images/hamburger-icon.png";
import crossIcon from "../../common/assets/images/cross-icon.png";
import beachBodyLogo from "../../common/assets/images/beachbody-logo.png";
import appConstants from "../../constants/constants";
import "./NavbarComponent.scss";

export default class NavbarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false
    };
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ isMenuOpen: state.isOpen });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({ isMenuOpen: false });
  }

  render() {
    const { navDetails, configDetails } = this.props;
    const { isMenuOpen } = this.state;
    return (
      <div className="navbarContainer">
        <Navbar collapseOnSelect className="fixed-top" expand="lg">
          <Container>
            <Navbar.Brand href="" className="navTitle">
              <Image
                src={ProfileImage}
                className="d-inline-block brandIcon"
                alt={translateService.translate("brand_icon")}
              />
              {navDetails && navDetails.first_name && navDetails.last_name && (
                <div className="navName">
                  <span
                    className="navNameDetails navNameEllipses"
                    title={navDetails.first_name + navDetails.last_name}
                  >
                    {navDetails.first_name} {navDetails.last_name}
                  </span>
                </div>
              )}
            </Navbar.Brand>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="navBarCollapseContainer"
            >
              <div className="mr-auto"></div>
              <Nav>
                <Nav.Link href="#contact" className="navPillItem">
                  {translateService.translate("contact")}
                </Nav.Link>
                <Nav.Link
                  href="#aboutMe"
                  className="navPillItem marginPillItem"
                >
                  {translateService.translate("about_me")}
                </Nav.Link>
                {configDetails && configDetails.myGroups === true && (
                  <Nav.Link
                    href="#myGroups"
                    className="navPillItem marginPillItem"
                  >
                    {translateService.translate("my_groups")}
                  </Nav.Link>
                )}
                {configDetails && configDetails.moreAboutMe === true && (
                  <Nav.Link
                    href="#moreAboutMe"
                    className="navPillItem marginPillItem"
                  >
                    {translateService.translate("more_about_me")}
                  </Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {configDetails && (
          <Menu
            right
            customBurgerIcon={
              <img
                src={hamburgerIcon}
                alt={translateService.translate("hamburger_icon")}
              />
            }
            customCrossIcon={
              <img
                src={crossIcon}
                alt={translateService.translate("close_icon")}
              />
            }
            isOpen={isMenuOpen}
            className={isMenuOpen ? "hamMenuOpen" : "hamMenuClose"}
            onStateChange={state => this.handleStateChange(state)}
          >
            <div className="topMenu">
              <a
                href="#contact"
                className="topMenuItem"
                onClick={() => this.closeMenu()}
              >
                {translateService.translate("contact")}
              </a>
              <a
                href="#aboutMe"
                className="topMenuItem"
                onClick={() => this.closeMenu()}
              >
                {translateService.translate("about_me")}
              </a>
              {configDetails && configDetails.myGroups === true && (
                <a
                  href="#myGroups"
                  className="topMenuItem"
                  onClick={() => this.closeMenu()}
                >
                  {translateService.translate("my_groups")}
                </a>
              )}
              {configDetails && configDetails.moreAboutMe === true && (
                <a
                  href="#moreAboutMe"
                  className="topMenuItem"
                  onClick={() => this.closeMenu()}
                >
                  {translateService.translate("more_about_me")}
                </a>
              )}
            </div>
            <div className="menuSeparator"></div>
            <div className="bottomMenu">
              <a
                href={appConstants.bbLogoLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={beachBodyLogo}
                  alt={translateService.translate("beachbody_icon")}
                  className="beachbodyLogo"
                />
              </a>
              <div className="additionalLinksSection">
                {navDetails &&
                  (!!navDetails.showShop ||
                    !!navDetails.showGroups ||
                    !!navDetails.showCoach ||
                    !!navDetails.showBod) && (
                    <div className="additionalLinks">
                      {navDetails && navDetails.showShop === 1 && (
                        <a
                          id="shop"
                          href={appConstants.shopLink + navDetails.coachId}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bottomMenuItem"
                        >
                          {translateService.translate("shop")}
                        </a>
                      )}
                      {navDetails && navDetails.showGroups === 1 && (
                        <a
                          id="bodgroups"
                          href={appConstants.groupsLink + navDetails.coachId}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bottomMenuItem"
                        >
                          {translateService.translate("groups")}
                        </a>
                      )}
                      {navDetails && navDetails.showBod === 1 && (
                        <a
                          id="demand"
                          href={appConstants.bodLink + navDetails.coachId}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bottomMenuItem"
                        >
                          {translateService.translate("beachbody_on_demand")}
                        </a>
                      )}
                      {navDetails && navDetails.showCoach === 1 && (
                        <a
                          id="coach"
                          href={
                            appConstants.becomeACoachLink + navDetails.coachId
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bottomMenuItem"
                        >
                          {translateService.translate("become_a_coach")}
                        </a>
                      )}
                    </div>
                  )}
              </div>
            </div>
          </Menu>
        )}
      </div>
    );
  }
}
