import React from "react";
import { Image, Container } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import * as translateService from "../../common/services/translate.service";
import "./MoreAboutMeComponent.scss";
import ScrollableAnchor from "react-scrollable-anchor";

const MoreAboutMeComponent = props => {
  const { moreAboutMeDetails } = props;
  const date = new Date().getTime();
  return (
    <div className="moreAboutMeContainer">
      {moreAboutMeDetails &&
        (moreAboutMeDetails.aboutMeImage ||
          moreAboutMeDetails.question_inspiration ||
          moreAboutMeDetails.question_favorite_trainer ||
          moreAboutMeDetails.question_favorite_flavor ||
          moreAboutMeDetails.question_favorite_thing) && (
          <Container>
            <ScrollableAnchor id={"moreAboutMe"}>
              <div className="moreAboutMeHeader headerStyle text-center">
                {translateService.translate("more_about_me")}
              </div>
            </ScrollableAnchor>
            <div className="moreAboutMeContent justify-content-center">
              {moreAboutMeDetails && moreAboutMeDetails.aboutMeImage && (
                <div className="text-center aboutMeImageSection">
                  <Image
                    src={`${moreAboutMeDetails.aboutMeImage}?${date}`}
                    className="d-inline-block aboutMeImageImage"
                    alt={translateService.translate("profile_icon")}
                  />
                </div>
              )}
              {(moreAboutMeDetails.question_inspiration ||
                moreAboutMeDetails.question_favorite_trainer ||
                moreAboutMeDetails.question_favorite_flavor ||
                moreAboutMeDetails.question_favorite_thing) && (
                <div className="text-center">
                  <div className="moreAboutMeQuestionsSection">
                    {moreAboutMeDetails &&
                      moreAboutMeDetails.question_inspiration && (
                        <div className="questionList">
                          <p className="moreAboutMeQuestion">
                            {translateService.translate("question_inspiration")}
                          </p>
                          <div className="moreAboutMeAnswer">
                            {ReactHtmlParser(
                              moreAboutMeDetails.question_inspiration.replace(
                                /contenteditable="false"/g,
                                ""
                              )
                            )}
                          </div>
                        </div>
                      )}
                    {moreAboutMeDetails &&
                      moreAboutMeDetails.question_favorite_trainer && (
                        <div className="questionList">
                          <p className="moreAboutMeQuestion">
                            {translateService.translate(
                              "question_favorite_trainer"
                            )}
                          </p>
                          <div className="moreAboutMeAnswer">
                            {ReactHtmlParser(
                              moreAboutMeDetails.question_favorite_trainer.replace(
                                /contenteditable="false"/g,
                                ""
                              )
                            )}
                          </div>
                        </div>
                      )}
                    {moreAboutMeDetails &&
                      moreAboutMeDetails.question_favorite_flavor && (
                        <div className="questionList">
                          <p className="moreAboutMeQuestion">
                            {translateService.translate(
                              "question_favorite_flavor"
                            )}
                          </p>
                          <div className="moreAboutMeAnswer">
                            {ReactHtmlParser(
                              moreAboutMeDetails.question_favorite_flavor.replace(
                                /contenteditable="false"/g,
                                ""
                              )
                            )}
                          </div>
                        </div>
                      )}
                    {moreAboutMeDetails &&
                      moreAboutMeDetails.question_favorite_thing && (
                        <div className="questionList">
                          <p className="moreAboutMeQuestion">
                            {translateService.translate(
                              "question_favorite_thing"
                            )}
                          </p>
                          <div className="moreAboutMeAnswer">
                            {ReactHtmlParser(
                              moreAboutMeDetails.question_favorite_thing.replace(
                                /contenteditable="false"/g,
                                ""
                              )
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          </Container>
        )}
    </div>
  );
};
export default MoreAboutMeComponent;
