import React from "react";
import { Container, Col } from "react-bootstrap";
import * as translateService from "../../common/services/translate.service";
import BeachBodyIcon from "../../common/assets/images/team_beachbody.svg";
import appConstants from "../../constants/constants";
import "./FooterLinksComponent.scss";

export default class FooterLinksComponent extends React.Component {
  render() {
    const { bbLinksDetails } = this.props;
    return (
      <Container>
        <div className="footerLinksContainer">
          <Col className="footerNavLogo">
            <a
              href={appConstants.bbLogoLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={BeachBodyIcon}
                alt={translateService.translate("beachbody_icon")}
                className="beachBodyLogo"
              />
            </a>
          </Col>
          {bbLinksDetails &&
            (!!bbLinksDetails.showShop ||
              !!bbLinksDetails.showGroups ||
              !!bbLinksDetails.showCoach ||
              !!bbLinksDetails.showBod) && (
              <Col className="footerNames">
                {bbLinksDetails && bbLinksDetails.showShop === 1 && (
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={appConstants.shopLink + bbLinksDetails.coachId}
                      className="footerNameItem"
                    >
                      {translateService.translate("shop")}
                    </a>
                  </li>
                )}
                {bbLinksDetails && bbLinksDetails.showGroups === 1 && (
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={appConstants.groupsLink + bbLinksDetails.coachId}
                      className="footerNameItem"
                    >
                      {translateService.translate("groups")}
                    </a>
                  </li>
                )}
                {bbLinksDetails && bbLinksDetails.showBod === 1 && (
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={appConstants.bodLink + bbLinksDetails.coachId}
                      className="footerNameItem"
                    >
                      {translateService.translate("beachbody_on_demand")}
                    </a>
                  </li>
                )}
                {bbLinksDetails && bbLinksDetails.showCoach === 1 && (
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        appConstants.becomeACoachLink + bbLinksDetails.coachId
                      }
                      className="footerNameItem"
                    >
                      {translateService.translate("become_a_coach")}
                    </a>
                  </li>
                )}
              </Col>
            )}
        </div>
      </Container>
    );
  }
}
