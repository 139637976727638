import i18next from "i18next";
import enus from "./enus.json";
import engb from "./engb.json";
import enca from "./enca.json";
import enfr from "./enfr.json";
import esus from "./esus.json";
import frca from "./frca.json";
import frfr from "./frfr.json";

i18next.init({
  interpolation: {
    escapeValue: false
  },
  lng: "enus" | "enus" | "enca" | "enfr",
  fallbackLng: "enus",
  resources: {
    en_US: { translation: enus },
    es_US: { translation: esus },
    fr_CA: { translation: frca },
    en_GB: { translation: engb },
    en_CA: { translation: enca },
    en_FR: { translation: enfr },
    fr_FR: { translation: frfr }
  }
});

export default i18next;
