import React from 'react';
import {
  BrowserRouter as Router
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import MySiteContainer from './containers/MySiteContainer';

function App() {
  return (
    <Router path="/">
      <div className="App">
        <MySiteContainer />
      </div>
    </Router>
  );
}

export default App;
