import axios from "axios";
import Snackbar from "node-snackbar";
import * as translateService from "./translate.service";

export const getDetails = (apiUrl, showToaster = true) => {
  return axios({
    url: apiUrl,
    method: "GET",
  }).then(
    (response) => {
      if (response.status !== 200) {
        handleResponseError(response);
      }
      return response.data;
    },
    (error) => {
      handleError(error, showToaster);
    }
  );
};

export const handleResponseError = (response) => {
  throw new Error(`HTTP error, status =  ${response.status}`);
};

export const handleError = (error, showToaster) => {
  console.error(error.message);
  if (error && showToaster) {
    const errorMessage =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : translateService.translate("server_not_reachable");
    toastShow(errorMessage);
    return;
  }
};

export const getMySiteConfig = (data) => {
  let configData = {
    myGroups: false,
    moreAboutMe: false,
    additionalLinks: false,
  };

  if (
    data.displayGroups === 1 ||
    (data.groupsInfo && data.groupsInfo !== "<p><br></p>")
  ) {
    configData.myGroups = true;
  }

  if (
    data.question_inspiration ||
    data.question_favorite_trainer ||
    data.question_favorite_flavor ||
    data.question_favorite_thing ||
    data.aboutMeImage
  ) {
    configData.moreAboutMe = true;
  }

  if (
    (data.showShop && data.showShop === 1) ||
    (data.showFree && data.showFree === 1) ||
    (data.showCoach && data.showCoach === 1) ||
    (data.showBod && data.showBod === 1)
  ) {
    configData.additionalLinks = true;
  }
  return configData;
};

export const toastShow = (
  text = "",
  actionText = "",
  actionTextColor = "green",
  duration = 5000
) => {
  if (typeof Snackbar !== "undefined") {
    Snackbar.show({
      text,
      actionText,
      duration,
      actionTextColor,
    });
  }
};

export const getExtension = (filePath) => {
  if (filePath) {
    return filePath.toLowerCase().split(".").pop();
  }
  return;
};

export const getLocale = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const locale = urlParams.get("locale");
  return locale;
};

export const redirectToPreferredEnglishLocale = (
  preferredLanguage,
  urlLocale
) => {
  if (
    urlLocale.indexOf("en") !== -1 &&
    preferredLanguage.indexOf("en") !== -1 &&
    preferredLanguage !== urlLocale
  ) {
    if ("URLSearchParams" in window) {
      var searchParams = new URLSearchParams(window.location.search);
      searchParams.set("locale", preferredLanguage);
      window.location.search = searchParams.toString();
    }
  }
};
